<template>
  <div class="products-slider">
    <swiper
      ref="productsSlider"
      :options="swiperOptions">
      <swiper-slide
        v-if="isLoaded"
        v-for="(entity, key) in entities"
        :key="key">
        <article class="mc-product">
          <div class="mc-product__header">
            <img class="mc-product__image"
                 :src="entity.featuredImage.src | variation('240x240')"
                 :alt="entity.name"
                 loading="lazy">
          </div>
          <div class="mc-product__body">
            <h4 v-if="entity.brand" class="mc-product__brand">{{ entity.brand.name }}</h4>
            <h3 class="mc-product__title">{{ entity.name }}</h3>
            <div v-if="userUsername" class="mc-product__price-wrapper">
              <div>
                <span class="mc-product__price">{{ entity.price | currencyFormat }}</span>
                <span class="mc-product__currency">€</span>
              </div>
            </div>
            <div v-else-if="entity.isB2C" class="mc-product__price-wrapper">
              <div>
                <span class="mc-product__price">{{ entity.price | currencyFormat }}</span>
                <span class="mc-product__currency">€</span>
              </div>
            </div>
            <div v-else class="price-login-alert price-login-alert--mc-product">
              <div class="price-login-alert__inner">
                <span class="price-login-alert__icon">
                  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z" fill="#DD181F"></path></svg>
                </span>
                <span class="price-login-alert__text">Za prikaz cijene, potrebna je prijava.</span>
              </div>
            </div>
          </div>
          <a :href="'/proizvodi/category/sub-category/' + entity.slug" class="mc-product__link"></a>
          <div class="mc-product__footer">
            <button
              @click="addCartItem(entity)"
              class="mc-add-to-cart"
              :disabled="!userUsername && !entity.isB2C"
            >Dodaj u košaricu</button>
          </div>
        </article>
      </swiper-slide>

      <swiper-slide v-else>
        <article class="mc-product mc-product--loading">
          <div class="mc-product__header">
            <img class="mc-product__image"
                 src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                 alt="Loading product..."
                 loading="lazy">
          </div>
          <div class="mc-product__body">
            <h3 class="mc-product__title"></h3>
            <div class="price-login-alert price-login-alert--mc-product">
              <div class="price-login-alert__inner"></div>
            </div>
          </div>
          <div class="mc-product__footer">
            <button class="mc-add-to-cart"></button>
          </div>
        </article>
      </swiper-slide>
      <swiper-slide v-else>
        <article class="mc-product mc-product--loading">
          <div class="mc-product__header">
            <img class="mc-product__image"
                 src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                 alt="Loading product..."
                 loading="lazy">
          </div>
          <div class="mc-product__body">
            <h3 class="mc-product__title"></h3>
            <div class="price-login-alert price-login-alert--mc-product">
              <div class="price-login-alert__inner"></div>
            </div>
          </div>
          <div class="mc-product__footer">
            <button class="mc-add-to-cart"></button>
          </div>
        </article>
      </swiper-slide>
      <swiper-slide v-else>
        <article class="mc-product mc-product--loading">
          <div class="mc-product__header">
            <img class="mc-product__image"
                 src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                 alt="Loading product..."
                 loading="lazy">
          </div>
          <div class="mc-product__body">
            <h3 class="mc-product__title"></h3>
            <div class="price-login-alert price-login-alert--mc-product">
              <div class="price-login-alert__inner"></div>
            </div>
          </div>
          <div class="mc-product__footer">
            <button class="mc-add-to-cart"></button>
          </div>
        </article>
      </swiper-slide>
      <swiper-slide v-else>
        <article class="mc-product mc-product--loading">
          <div class="mc-product__header">
            <img class="mc-product__image"
                 src="/media/cache/resolve/240x240/build/images/ms-no-image.jpg"
                 alt="Loading product..."
                 loading="lazy">
          </div>
          <div class="mc-product__body">
            <h3 class="mc-product__title"></h3>
            <div class="price-login-alert price-login-alert--mc-product">
              <div class="price-login-alert__inner"></div>
            </div>
          </div>
          <div class="mc-product__footer">
            <button class="mc-add-to-cart"></button>
          </div>
        </article>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ApiService from '../../services/api.service'
import { mapActions } from 'vuex'

export default {
  name: 'ProductsCarousel',
  filters: {
    variation: function(src, size) {
      if (!size) return  '/uploads/' + src
      if (!src) return '/media/cache/resolve/' + size + '/build/images/ms-no-image.jpg'
      return '/media/cache/resolve/' + size + src
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    endpoint: {
      type: String,
      required: true
    },
    filter: {
      type: String,
      required: false
    },
    userUsername: {
      type: String,
      required: true
    }
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      entities: [],
      isLoaded: false,
      swiperOptions: {
        slidesPerView: 1.4,
        spaceBetween: 10,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.productsSlider.$swiper
    }
  },
  mounted() {
    ApiService.get(this.endpoint, this.filter).then(({ data }) => {
      this.entities = [...data['hydra:member']]

      setTimeout(() => {
        this.isLoaded = true
      }, 2000)
    })
  },
  methods: {
    ...mapActions(["addCartItem"]),
  }
}
</script>

<style lang="scss">
@import "~include-media/dist/include-media";

.products-slider {

  .swiper-wrapper {
    padding: 0 0 45px 0;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 0 0 60px 0;
    }
  }

  .swiper-slide-active {

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-left: 30px;
      filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #DD181F;
  }
}
</style>
