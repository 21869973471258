var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mc-main-slider" }, [
    _c(
      "div",
      { staticClass: "mc-main-slider__inner" },
      [
        _c(
          "swiper",
          { ref: "postsSlider", attrs: { options: _vm.swiperOptions } },
          [
            _vm._l(_vm.entities, function (slide, key) {
              return _c("swiper-slide", { key: key }, [
                _c("div", { staticClass: "mc-main-slider__slide" }, [
                  _c(
                    "div",
                    {
                      staticClass: "mc-main-slider__meta",
                      attrs: { "data-swiper-parallax": "-200" },
                    },
                    [
                      slide.brand
                        ? _c("span", { staticClass: "mc-main-slider__brand" }, [
                            _vm._v(_vm._s(slide.brand.name)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass: "mc-main-slider__title",
                          attrs: { "data-swiper-parallax": "-300" },
                        },
                        [_vm._v(_vm._s(slide.name))]
                      ),
                      _vm._v(" "),
                      slide.description
                        ? _c(
                            "p",
                            {
                              staticClass: "mc-main-slider__description",
                              attrs: { "data-swiper-parallax": "-200" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.truncate(slide.description, 120))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userUsername
                        ? _c(
                            "div",
                            {
                              staticClass: "mc-main-slider__price-wrapper",
                              attrs: { "data-swiper-parallax": "-220" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "mc-main-slider__price" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currencyFormat")(slide.price)
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "mc-main-slider__currency" },
                                  [_vm._v("€")]
                                ),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "price-login-alert price-login-alert--main-slider",
                              attrs: { "data-swiper-parallax": "-220" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "price-login-alert__inner" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "price-login-alert__icon" },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "14",
                                            height: "14",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z",
                                              fill: "#DD181F",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "price-login-alert__text" },
                                    [
                                      _vm._v(
                                        "Za prikaz cijene, potrebna je prijava."
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "mc-add-to-cart mc-add-to-cart--border mc-add-to-cart--w216",
                          attrs: {
                            href:
                              "/proizvodi/" +
                              slide.category.root.slug +
                              "/" +
                              slide.category.slug +
                              "/" +
                              slide.slug,
                            "data-swiper-parallax": "-120",
                          },
                        },
                        [_vm._v("Saznaj više")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mc-main-slider__image-wrapper",
                      attrs: { "data-swiper-parallax": "-100" },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(min-width:650px)",
                            srcset: _vm._f("variation")(
                              slide.featuredImage.src,
                              "445x445"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width:465px)",
                            srcset: _vm._f("variation")(
                              slide.featuredImage.src,
                              "445x260"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "mc-main-slider__image",
                          attrs: {
                            src: _vm._f("variation")(
                              slide.featuredImage.src,
                              "445x260"
                            ),
                            alt: slide.name,
                            loading: "lazy",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
              ])
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "swiper-pagination",
              attrs: { slot: "pagination" },
              slot: "pagination",
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }