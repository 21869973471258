var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "quick-login",
    },
    [
      _c(
        "div",
        { staticClass: "quick-login__header", on: { click: _vm.toggle } },
        [
          !_vm.userUsername
            ? _c("span", { staticClass: "quick-login__text" }, [
                _vm._v("Prijavi se"),
              ])
            : _c("span", { staticClass: "quick-login__text" }, [
                _vm._v(_vm._s(_vm.nickName)),
              ]),
          _vm._v(" "),
          _c("span", { staticClass: "quick-login__link-icon" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "16",
                  height: "16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M8 0a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4zm0 7c2.67 0 8 1.33 8 4v3H0v-3c0-2.67 5.33-4 8-4zm0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V13c0-.64-3.13-2.1-6.1-2.1z",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isOpen
        ? _c("div", { staticClass: "quick-login__body" }, [
            !_vm.userUsername
              ? _c("form", { attrs: { method: "post", action: "/prijava" } }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "_csrf_token" },
                    domProps: { value: _vm.csrfToken },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "quick-login__button",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Prijavi se")]
                  ),
                  _vm._v(" "),
                  _vm._m(2),
                ])
              : _c("div", [
                  _c("p", [
                    _vm._v("Prijavljeni ste kao: "),
                    _c("strong", [_vm._v(_vm._s(_vm.userUsername))]),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticStyle: { opacity: "0.3" } }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _vm._v("Viši nivo:\n        "),
                      _c("span", [
                        _c("label", { staticClass: "switch" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isHigherLevelChecked,
                                expression: "isHigherLevelChecked",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.isHigherLevelChecked)
                                ? _vm._i(_vm.isHigherLevelChecked, null) > -1
                                : _vm.isHigherLevelChecked,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleHigherLevel()
                              },
                              change: function ($event) {
                                var $$a = _vm.isHigherLevelChecked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.isHigherLevelChecked = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.isHigherLevelChecked = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.isHigherLevelChecked = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "slider round" }),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "quick-login__button",
                      attrs: { href: "/moje-narudzbe" },
                    },
                    [_vm._v("Moje narudžbe")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "quick-login__button2",
                      attrs: { href: "/odjava" },
                    },
                    [_vm._v("Odjava")]
                  ),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "quick-login__form-group" }, [
      _c("label", { staticClass: "quick-login__form-label" }, [
        _vm._v("Korisničko ime ili e-mail:"),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "quick-login__form-input",
        attrs: {
          type: "email",
          name: "email",
          id: "inputEmail",
          required: "",
          autofocus: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "quick-login__form-group" }, [
      _c("label", { staticClass: "quick-login__form-label" }, [
        _vm._v("Lozinka "),
        _c(
          "a",
          {
            staticClass: "quick-login__form-label-link",
            attrs: { href: "/reset-password" },
          },
          [_vm._v("(Zaboravljena lozinka?)")]
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "quick-login__form-input",
        attrs: {
          type: "password",
          name: "password",
          id: "inputPassword",
          required: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "quick-login__register" }, [
      _vm._v("\n        Nemaš korisnički račun? "),
      _c(
        "a",
        { staticClass: "quick-login__link", attrs: { href: "/registracija" } },
        [_vm._v("Registriraj se")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }