<template>
  <div>
    <div>
      <ProductsSearch
          :userUsername="userUsername"
          @onSelectProduct="onSelectProduct"
      ></ProductsSearch>
    </div>
    <div class="items-wrapper">
      <div v-for="(product, key) in selectedProducts"
           :key="key" class="qs-item">
        <div class="qs-item__name">
          {{ product.name }}
        </div>
        <div class="qs-item__quantity">
          <input :id="'quantity-' + product.id"
                 class="qs-item__quantity-input"
                 type="number"
                 :max="product.stockQuantity"
                 min="1">
          <button class="mc-button">ukloni</button>
        </div>
      </div>
    </div>
    <div v-if="selectedProducts.length > 0" class=actions>
      <button class="mc-button mc-button--quick-shopping">Dodaj u košaricu</button>
    </div>
  </div>
</template>

<script>
import ProductsSearch from "./ProductsSearch.vue";
import ApiService from "../../services/api.service";

export default {
  name: 'QuickShopping',
  components: {
    ProductsSearch
  },
  props: ['userUsername'],
  data() {
    return {
      selectedProducts: []
    }
  },
  methods: {
    onSelectProduct(id) {
      ApiService.get('/api/v1/products', id).then(({data}) => {
        this.selectedProducts.push(data)
        setTimeout(() => {
          document.getElementById('quantity-' + data.id).focus()
        }, 300)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  margin: 30px auto;
  padding: 0 72px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.items-wrapper {
  margin: 0 auto;
  padding: 0 72px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.qs-item {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;

  &__name {
    font-size: 1.2rem;
    font-weight: 600;
  }
  &__quantity {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
    {
      -webkit-appearance: unset;
      margin: unset;
    }

    input[type=number]
    {
      -moz-appearance: unset;
    }

    button {
      font-weight: 500;
      font-size: 0.9rem;
      background-color: #C7161C;
      color: #ffffff;
      padding: 2px 10px;
    }
  }
  &__quantity-input {
    margin-right: 30px;
    border: 1px solid #f2f2f2;
    padding: 10px;
  }
}
</style>
