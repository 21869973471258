var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("ProductsSearch", {
          attrs: { userUsername: _vm.userUsername },
          on: { onSelectProduct: _vm.onSelectProduct },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "items-wrapper" },
      _vm._l(_vm.selectedProducts, function (product, key) {
        return _c("div", { key: key, staticClass: "qs-item" }, [
          _c("div", { staticClass: "qs-item__name" }, [
            _vm._v("\n        " + _vm._s(product.name) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qs-item__quantity" }, [
            _c("input", {
              staticClass: "qs-item__quantity-input",
              attrs: {
                id: "quantity-" + product.id,
                type: "number",
                max: product.stockQuantity,
                min: "1",
              },
            }),
            _vm._v(" "),
            _c("button", { staticClass: "mc-button" }, [_vm._v("ukloni")]),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.selectedProducts.length > 0
      ? _c("div", { staticClass: "actions" }, [
          _c("button", { staticClass: "mc-button mc-button--quick-shopping" }, [
            _vm._v("Dodaj u košaricu"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }