var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.entities.length > 0
    ? _c("section", { staticClass: "mc-news-row" }, [
        _c("div", { staticClass: "mc-news-row__header" }, [
          _c("h2", { staticClass: "mc-news-row__title" }, [
            _vm.title
              ? _c("span", { staticClass: "mc-products-row__title-400" }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.subtitle
              ? _c("span", { staticClass: "mc-products-row__title-700" }, [
                  _vm._v(_vm._s(_vm.subtitle)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "mc-news-row__origin", attrs: { href: "/novosti" } },
            [_vm._v("Pogledaj sve novosti")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mc-news-row__body mc-news-row__body--slider" },
          [
            _c("PostsCarousel", {
              attrs: { entities: _vm.entities, "is-loaded": _vm.isLoaded },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }