<template>
  <main class="products">
    <div class="products__header">
      <ProductsQuickSearch :user-username="userUsername"/>
    </div>
    <div class="products__body">
      <div class="products__aside">
        <div
          v-for="(attribute, attrKey) in filteredAttributes"
          :key="attribute.slug"
          class="products__aside-group">
          <div class="filter filter--checkbox">
            <div class="filter__header">
              <h3 class="filter__title">{{ attribute.title }}</h3>
            </div>
            <div class="filter__body">
              <div v-for="(item, key) in attribute.items" :key="key" class="filter__form-group">
                <label @click="onFilterClick(item, attrKey)" class="checkbox" style="--color: #fff;">
									<span class="checkbox__input">
										<input type="checkbox">
										<span class="checkbox__control">
											<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
											<path fill='none' stroke='currentColor' stroke-width='4' d='M1.73 12.91l6.37 6.37L22.79 4.59'/></svg>
										</span>
									</span>
                  <span class="checkbox__label">{{ item.title }}</span>
                </label>
                <div v-if="!isProductsLoaded" class="filter__dis"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="products__main">
        <div class="products__top">
          <h1 class="products__title">Proizvodi ({{ filteredProducts.length }}) kom</h1>
          <div class="products__top-actions">
            <div class="products__filter-toggle" @click="toggleFilters">
              <div class="products__filter-toggle-inner">
                <span class="products__filter-toggle-title">Filtriraj</span>
                <span class="products__filter-toggle-icon">
                  <svg width="23" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.571 2.286H0V0h20.571v2.286zm-8.217 9.143H8v2.285h3.531a6.84 6.84 0 01.823-2.285zm4.789-5.715H3.429V8h13.714V5.714zm0 4.572v3.428h-3.429V16h3.429v3.429h2.286V16h3.428v-2.286H19.43v-3.428h-2.286z" fill="#000"/></svg>
                </span>
              </div>
            </div>
            <div v-click-outside="closeSort" class="products__sort-toggle">
              <div @click="toggleSort" class="products__sort-toggle-inner">
                <span class="products__sort-toggle-title">Sortiraj</span>
                <span class="products__sort-toggle-icon" :class="'n' ? activePriceSort : 'v'">
                  <svg width="22" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6 19l-4.4-4.222h3.3V4.222h-3.3L17.6 0 22 4.222h-3.3v10.556H22L17.6 19zM0 16.889v-2.111h11v2.11H0zm0-6.333V8.444h7.7v2.112H0zm0-6.334v-2.11h4.4v2.11H0z" fill="#000"/></svg>
                </span>
              </div>
              <div v-if="isSortVisible" class="products__sort-toggle-dropdown">
                <ul>
                  <li @click="onSortItemClick('n')"><strong>Cijena:</strong> manja prema većoj</li>
                  <li @click="onSortItemClick('v')"><strong>Cijena:</strong> veća prema manjoj</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="products__items">
          <div v-if="!isProductsLoaded" style="width: 100%; text-align: center;">
            <svg class="loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path fill="#DD353D" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
              <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite" />
            </path>
          </svg>
          </div>
          <ProductItem
            v-if="product.hasOwnProperty('category')"
            v-for="product in filteredProducts"
            :key="product.id"
            :product="product"
            :user-username="userUsername"
          />
        </div>
      </div>
    </div>
    <div class="products__footer"></div>
  </main>
</template>

<script>
import ProductItem from '../components/product-item/ProductItem'
import ApiService from "../services/api.service"
import ProductsFilter from "../components/products-filter/ProductsFilter";
import ProductsQuickSearch from "../components/products-quick-search/ProductsQuickSearch";
import ClickOutside from "vue-click-outside";

export default {
  name: 'ProductsSubCategory',
  props: {
    userUsername: {
      type: String,
      required: true
    },
    category: {
      type: String
    },
    subCategory: {
      type: String
    }
  },
  components: {
    ProductItem,
    ProductsFilter,
    ProductsQuickSearch
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      products: [],
      filteredProducts: [],
      allProducts: [],
      lastFilteredProducts: [],
      isProductsLoaded: false,

      attributes: [],
      filteredAttributes: [],
      isAttributesLoaded: false,

      selectedFilters: [],

      itemsPerPage: 18,
      currentPage: 1,
      isLoadMoreButtonVisible: true,

      currentQuery: '',

      isSortVisible: false,
      activePriceSort: 'n',

      count: 0,
    }
  },
  mounted() {
    this.getProducts('/api/v1/products', `?properties[]=slug&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=shortDescription&properties[]=category&properties[]=featuredImage&properties[]=attributes&properties[]=brand&isPublish=true&category.slug=${this.subCategory}&itemsPerPage=200}`).then(({ data }) => {
      this.allProducts = [...data['hydra:member']]
      this.filteredProducts = [...data['hydra:member']]
      this.isProductsLoaded = true

      this.allProducts.forEach(product => {

        product.attributes.forEach((attribute, key) => {

          if (!attribute.parent) {
            this.filteredAttributes.push({
              root: attribute.root,
              parent: attribute.parent,
              title: attribute.title,
              slug: attribute.slug,
              position: attribute.position,
              items: [],
            })
          }
        })

        this.filteredAttributes.forEach((attr1, key1) => {
          this.attributes.forEach(attr2 => {
            if (attr2.parent) {
              if (attr1.slug === attr2.parent.slug) {
                this.filteredAttributes[key1].items.push({
                  root: attr2.root,
                  parent: attr2.parent,
                  title: attr2.title,
                  slug: attr2.slug,
                  position: attr2.position
                })
              }
            }
          })
        })

        this.isAttributesLoaded = true
      })

      this.getAttributes('/api/v1/attributes', `?productCategory.slug=${this.subCategory}&order[position]=asc`).then(({ data }) => {
        this.attributes = [...data['hydra:member']]

        this.attributes.forEach((attribute, key) => {
          if (!attribute.parent) {
            this.filteredAttributes.push({
              root: attribute.root,
              parent: attribute.parent,
              title: attribute.title,
              slug: attribute.slug,
              position: attribute.position,
              items: [],
            })
          }
        })

        this.filteredAttributes.forEach((attr1, key1) => {
          this.attributes.forEach(attr2 => {
            if (attr2.parent) {
              if (attr1.slug === attr2.parent.slug) {
                this.filteredAttributes[key1].items.push({
                  root: attr2.root,
                  parent: attr2.parent,
                  title: attr2.title,
                  slug: attr2.slug,
                  position: attr2.position
                })
              }
            }
          })
        })

        this.currentQuery = `?category=${this.subCategory}`

        this.isAttributesLoaded = true
      })
    })
  },
  methods: {
    async getProducts(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getAttributes(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    },
    onFilterClick(item, key) {
      this.count ++

      if (this.count === 2) {
        this.count = 0
        this.filteredProducts = []
        this.currentQuery = `?category=${this.subCategory}`

        const isSelected = this.selectedFilters.filter(filter => filter.slug === item.slug)

        if (isSelected.length === 0) {
          this.selectedFilters.push({
            parent: item.parent.slug,
            parameter: item.parent.isGroup ? `&attributes.slug[${item.position + key++}]=` : `&attributes.slug[${key}][]=`,
            slug: item.slug,
            position: item.position
          })
        }
        else {
          this.selectedFilters = this.selectedFilters.filter((filter) => filter.slug !== item.slug)
        }

        this.selectedFilters.forEach(filter => {
          this.currentQuery += filter.parameter + filter.slug
        })

        this.isProductsLoaded = false

        this.getProducts('/api/v1/products/attribute-filter', this.currentQuery).then(({ data }) => {
          this.filteredProducts = data.products
          this.isProductsLoaded = true
        })
      }
    },
    toggleFilters() {
      const aside = document.querySelector('.products__aside')
      aside.classList.toggle('products__aside-show')
    },
    toggleSort() {
      this.isSortVisible = !this.isSortVisible
    },
    closeSort() {
      this.isSortVisible = false
    },
    onSortItemClick(payload) {
      if (payload === 'v') this.activePriceSort = 'v'
      else this.activePriceSort = 'n'

      if (this.activePriceSort === 'v') this.filteredProducts = this.filteredProducts.sort((a, b) => b.price - a.price)
      else this.filteredProducts = this.filteredProducts.sort((a, b) => a.price - b.price)

      this.isSortVisible = false
    },
  }
}
</script>
