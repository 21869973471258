<template>
  <article class="mc-product mc-product--w33">
    <div class="mc-product__header">
<!--      <span class="mc-product__comparison-icon" @click.prevent="addToComparisonList(product.id)" title="Usporedi">-->
<!--        <svg width="16px" height="16px">-->
<!--          <use xlink:href="/build/images/svg/sprite.svg#compare-16"></use>-->
<!--        </svg>-->
<!--      </span>-->
      <img class="mc-product__image"
           :src="product.featuredImage.src | variation('240x240')"
           :alt="product.name"
           loading="lazy">
    </div>
    <div class="mc-product__body">
      <h4 v-if="product.brand"
          class="mc-product__brand"
          :style="product.brand.color ? 'color: ' + product.brand.color : 'color: #BFBFBF'">{{ product.brand.name }}</h4>
      <h3 class="mc-product__title">{{ product.name }}</h3>
      <p class="mc-product__short-description">{{ product.shortDescription }}</p>
      <div style="display: flex; flex-wrap: wrap; margin-bottom: 5px">
        <span v-for="(attribute, key) in orderBy(product.attributes, 'position')"
              :key="key"
              :style="product.brand.color ? 'background-color: ' + product.brand.color : 'background-color: #DD181F'"
              style="color: #fff; margin-right: 6px; margin-bottom: 6px; padding: 3px 6px; font-size: 0.8em; border-radius: 2px"
              :data-order="attribute.position"
        >{{ attribute.title }}</span>
      </div>
      <div v-if="userUsername" class="mc-product__price-wrapper">
        <div class="mc-product__price-kn">
          <span class="mc-product__price">{{ product.price|currencyFormat }}</span>
          <span class="mc-product__currency">€</span>
        </div>
      </div>
      <div v-else-if="product.isB2C" class="mc-product__price-wrapper">
        <div class="mc-product__price-kn">
          <span class="mc-product__price">{{ product.price|currencyFormat }}</span>
          <span class="mc-product__currency">€</span>
        </div>
      </div>
      <div v-else class="price-login-alert price-login-alert--mc-product">
        <div class="price-login-alert__inner">
          <span class="price-login-alert__icon">
            <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z" fill="#DD181F"></path></svg>
          </span>
          <span class="price-login-alert__text">Za prikaz cijene, potrebna je prijava.</span>
        </div>
      </div>
    </div>
    <a :href="'/proizvodi/' + product.category.root.slug + '/' + product.category.slug + '/' + product.slug" class="mc-product__link"></a>
  </article>
</template>

<script>
import axios from "axios";
import Vue2Filters from 'vue2-filters'

export default {
  name: 'ProductItem',
  mixins: [Vue2Filters.mixin],
  props: {
    product: {
      type: Object,
      required: true
    },
    userUsername: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    addToComparisonList(id) {
      axios.post(`${window.location.origin}/comparison/add/${id}`).then((data) => {
        console.log('data', data)
      })
    }
  }
}
</script>
