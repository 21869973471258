var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "posts-slider" },
    [
      _c(
        "swiper",
        { ref: "postsSlider", attrs: { options: _vm.swiperOptions } },
        _vm._l(_vm.entities, function (entity, key) {
          return _vm.isLoaded
            ? _c("swiper-slide", { key: key }, [
                _c("article", { staticClass: "mc-post-card" }, [
                  _c("div", { staticClass: "mc-post-card__header" }, [
                    _c("img", {
                      staticClass: "mc-post-card__image",
                      attrs: {
                        src: _vm._f("variation")(
                          entity.featuredImage.src,
                          "350x200"
                        ),
                        alt: entity.title,
                        loading: "lazy",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mc-post-card__body" }, [
                    _c("div", { staticClass: "mc-post-card__meta-wrapper" }, [
                      _c("span", { staticClass: "mc-post-card__date" }, [
                        _vm._v(_vm._s(_vm._f("formatDate")(entity.createdAt))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("h3", { staticClass: "mc-post-card__title" }, [
                      _vm._v(_vm._s(entity.title)),
                    ]),
                    _vm._v(" "),
                    entity.shortDescription
                      ? _c("p", {
                          staticClass: "mc-post-card__content",
                          domProps: {
                            innerHTML: _vm._s(entity.shortDescription),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("a", {
                    staticClass: "mc-post-card__link",
                    attrs: { href: "/novosti/" + entity.slug },
                  }),
                ]),
              ])
            : _vm._e()
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }