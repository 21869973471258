var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mc-brands-row" }, [
    _c("div", { staticClass: "mc-brands-row__header" }, [
      _c("h2", { staticClass: "mc-brands-row__title" }, [
        _c("span", { staticClass: "mc-brands-row__title-700" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "mc-brands-row__title-400" }, [
          _vm._v(_vm._s(_vm.subtitle)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mc-brands-row__body" },
      _vm._l(_vm.entities, function (brand, key) {
        return _c("div", { key: key, staticClass: "mc-brands__item" }, [
          _c("a", { attrs: { href: brand.webUrl, target: "_blank" } }, [
            _c("img", {
              staticClass: "mc-brands__brand-img",
              attrs: {
                src: _vm._f("variation")(brand.featuredImage.src, "160x65"),
                alt: brand.name,
                loading: "lazy",
              },
            }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }