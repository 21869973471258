import { render, staticRenderFns } from "./ProductsSlider.vue?vue&type=template&id=103d3256&scoped=true"
import script from "./ProductsSlider.vue?vue&type=script&lang=js"
export * from "./ProductsSlider.vue?vue&type=script&lang=js"
import style0 from "./ProductsSlider.vue?vue&type=style&index=0&id=103d3256&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "103d3256",
  null
  
)

export default component.exports